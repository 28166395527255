import logo from './img/longlogo.svg';
import discord from "./img/discord.svg"
import medium from "./img/medium.svg"
import twitter from "./img/twitter.svg"
import github from "./img/github.svg"
import longmon from "./img/longmon.svg"
import cloud1 from "./img/cloud1.svg"
import cloud2 from "./img/cloud2.svg"
import './App.scss';
import { Component } from 'react';

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      "days": "-",
      "hours": "-",
      "minutes": "-",
      "seconds": "-",
      "redirect":false
    }
    this.getTime = this.getTime.bind(this)
  }
  getTime = () => {
    var launchDate = new Date(Date.UTC(2021, 7, 14, 14, 0, 0, 0)).getTime()
    var localDate = new Date().getTime()
    var distance = launchDate - localDate
    if (distance <= 0)
    {
      this.setState({
        "redirect":true
      })
    }
    var days = Math.floor(distance / (1000 * 60 * 60 * 24))
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
    var seconds = Math.floor((distance % (1000 * 60)) / (1000))
    this.setState({
      "days": days,
      "hours": hours,
      "minutes": minutes,
      "seconds": seconds,
      "mouseX":0
    })
  }
  componentDidMount() {
    this.timer = setInterval(() => {
      this.getTime()
    }
      , 1000
    )
  }

  handleMouseMove = (e) => {
    this.setState({
      mouseX: (e.clientX - e.clientY) * 0.1
    })
  }

  componentWillUnmount() {
    clearInterval(
      this.timer
    )
  }

  render() {
    if (this.state.redirect) {
      window.location.replace('https://beta.longlong.finance/')
      return (
        <>
        </>
      )
    }
    return (
      <div className="app">
        <div className="navbar">
          <div className="logo">
            <img src={logo} alt="longlong finance logo" />
            <div id="logo-text">
              LongLong Finance
            </div>
          </div>
        </div>

        <div className="hero" onMouseMove={this.handleMouseMove}>
          <div id="protocol-countdown-title">
            Protocol Launch Count Down
          </div>
          <div id="protocol-countdown-time">
            {this.state.days} days {this.state.hours} hours {this.state.minutes} minutes {this.state.seconds} secs
          </div>
          <div id="doc-link">
            <a href="https://docs.longlong.finance/">view our doc</a>
          </div>
          <div className="social-btn-container">
            <a href="https://twitter.com/longlongfinance">
              <img src={twitter} alt="" />
            </a>
            <a href="https://discord.com/invite/DnRGW7fpyJ">
              <img src={discord} alt="" />
            </a>
            <a href="https://medium.com/@longlong.finance">
              <img src={medium} alt="" />
            </a>
            <a href="http://github.com/longlong-finance">
              <img src={github} alt="" />
            </a>
          </div>
          <img id="longmon" src={longmon} style={{
            transform: `translate(0px,${-1 * this.state.mouseX}px)`,
            transition: `transform 1s`
          }} alt="" />
          <img id="cloud1" src={cloud1} style={{
            transform: `translate(0px,${1 * this.state.mouseX}px`,
            transition: `transform 1s`
          }} alt="" />
          <img id="cloud2" src={cloud2} style={{
            transform: ` translate(0px,${1 * this.state.mouseX}px)`,
            transition: `transform 1s`
          }} alt="" />
        </div>

      </div>
    );
  }

}

export default App;
